import { CSSProperties, ReactEventHandler } from 'react';

export type ImagePropsType = {
  src: string;
  alt?: string | null;
  className?: string;
  style?: CSSProperties | null;
  width?: number | string;
  height?: number | string;
  onLoad?: ReactEventHandler<HTMLImageElement> | null;
  onError?: ReactEventHandler<HTMLImageElement> | null;
  preload?: boolean;
  placeholderSrc?: string;
};

export default function Image({
  src,
  alt = '',
  className = '',
  style = null,
  width,
  height,
  onLoad = () => undefined,
  onError = () => undefined,
  preload = false,
  placeholderSrc
}: ImagePropsType) {
  let counter = 0;
  return (
    <img
      //   src={src.replace('.svg', '.png')}
      src={preload ? placeholderSrc : src}
      alt={alt}
      className={className}
      style={style}
      width={width}
      height={height}
      onLoad={(e) => {
        if (counter == 0) {
          if (preload) {
            setTimeout(() => {
              (e.target as HTMLImageElement).src = src;
            }, 100);
          }
          counter++;
          onLoad(e);
        }
      }}
      onError={(e) => {
        if (counter == 0) {
          if (preload) {
            setTimeout(() => {
              (e.target as HTMLImageElement).src = src;
            }, 100);
          }
          counter++;
          onError(e);
        }
      }}
    />
  );
}
